import axios from 'axios';
// Fonction pour récupérer la température depuis Strapi et l'afficher
export const fetchStrapi = async (singleType, elements) => {
    try {
        // Récupérer la température depuis Strapi
        const response = await axios.get(`https://strapi.engles.fr/${singleType}`);
        // Afficher la val dans l'élément correspondant
        for (const k in elements){
		const element = elements[k]
		const key = element.getAttribute("key");
		const val = response.data[key];
		console.log(key)
		if (element.textContent !== undefined) {
			element.textContent = val;
		} 
		if (element.value !== undefined) {
			element.value = val;
		} 
	}
    } catch (error) {
        console.error('Erreur lors de la récupération de la température', error);
    }
};


// Fonction pour mettre à jour la température
const updateTemperature = async (element) => {
	const key = element.getAttribute("key");
	const singleType = element.getAttribute("strapi");

	const elts = document.querySelectorAll("input[strapi]");
	for( const k in elts){
		const e = elts[k];
		if(e.getAttribute("key") == key && e.getAttribute("strapi") == singleType){
			const inputElement = e;
			const newTemperature = inputElement.value;
			try {
				// Envoyer une requête PUT pour mettre à jour la température dans Strapi
				const response = await axios.put(`https://strapi.engles.fr/${singleType}`, {
					temperature: newTemperature
				});
				// Mettre à jour l'affichage de la température
				const temperatureElement = document.getElementById(key);
				temperatureElement.textContent = newTemperature;
			} catch (error) {
				console.error('Erreur lors de la mise à jour de la température', error);
			}
			break;
		}
	}


};

// Gérer le clic sur le bouton de mise à jour
const updateStrapi = document.getElementById('updateStrapi');
var elements = document.querySelectorAll("button[strapi]");
elements.forEach((element) => {
	element.addEventListener('click', function() {
		  updateTemperature(element);
	});
});

elements = document.querySelectorAll("div[strapi], span[strapi], input[strapi]");

// Créer un objet pour regrouper les éléments par la combinaison strapiValue + keyValue
const groupedElements = {};

// Parcourir les éléments sélectionnés
elements.forEach((element) => {
  // Récupérer les valeurs des attributs "strapi" et "key"
  const strapiValue = element.getAttribute("strapi");

  // Vérifier si le groupe existe déjà
  if (!groupedElements.hasOwnProperty(strapiValue)) {
    // Créer un nouveau groupe avec un tableau vide
    groupedElements[strapiValue] = [];
  }

  // Ajouter l'élément au groupe correspondant
  groupedElements[strapiValue].push(element);
});

// Parcourir les groupes et appeler fetchstrapi une fois par groupe
for (const strapiValue in groupedElements) {
  if (groupedElements.hasOwnProperty(strapiValue)) {
    // Récupérer le tableau d'éléments pour le groupe
    const elementsList = groupedElements[strapiValue];


    // Appeler la fonction "fetchstrapi" avec le groupe d'éléments en tant que troisième paramètre
    fetchStrapi(strapiValue, elementsList);
  }
}
